import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './component/Home';
import Navbar from './component/Navbar';
import About from './component/About';
import Contact from './component/Contact';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { Head } from './component/Head';
import Photoes from './component/Photoes';
import Plans from './component/Plans';


function App() {
  return (
    <div className='mains'>

      <Router>
        <Head />
        <Navbar />

        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Photoes" element={<Photoes />} />
          <Route path="/Plans" element={<Plans />} />
        </Routes>

      </Router>

    </div>
  );
}

export default App;
