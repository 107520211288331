import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from './Img/newbaner.jpg';
import Banner2 from './Img/laddak.jpg';
import Banner3 from './Img/banner3.jpg';


function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item className='carosal'>
        <img className="d-block " src={Banner1} alt="First slide" />
      </Carousel.Item>

      
      

      
      <Carousel.Item className='carosal'>
        <img className="d-block " src={Banner3} alt="Second slide" />
      </Carousel.Item>

      <Carousel.Item className='carosal'>
        <img className="d-block " src={Banner2} alt="Second slide" />
      </Carousel.Item>

    </Carousel>
  );
}

export default UncontrolledExample;
