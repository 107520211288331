import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from './Img/logo.png';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-bootstrap';

function BasicExample() {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false); 
    };

    return (
        <div className='navigation'>

               {/* <div className="watermark">EMPIRE TRAVEL SERVICES</div>  */}
            <Navbar expand="lg" expanded={expanded}>
                <Container>
                    <LinkContainer to="/Home">
                        <Navbar.Brand>
                            <img src={Logo} alt="Logo" style={{ marginLeft: '-50px' }} />
                        </Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <LinkContainer to="/" onClick={handleNavItemClick}>
                                <Nav.Link>HOME</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/About" onClick={handleNavItemClick}>
                                <Nav.Link>ABOUT</Nav.Link>
                            </LinkContainer>

                            <NavDropdown title=" OUR TRIP " id="basic-nav-dropdown"  >
                                <LinkContainer to="/">
                                    <NavDropdown.Item  >Birthday party</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Pre wedding shoot</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Event</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Pickup and drop</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Marriage</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Ayodhya darshan round trip </NavDropdown.Item>
                                </LinkContainer>

                               

                            </NavDropdown>

                            <NavDropdown title="  OUR VEHICLES" id="basic-nav-dropdown" >
                                <LinkContainer to="/">
                                    <NavDropdown.Item>Audi A6</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Mercedes E class </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Jaguar Xf </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Maruti Suzuki Dzire</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>BMW</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item> Toyota Fortuner </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item> Maruti Ertiga </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Honda City</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Honda Amaze </NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item> Maruti Suzuki Ciaz</NavDropdown.Item>
                                </LinkContainer>

                        
                                <LinkContainer to="/">
                                    <NavDropdown.Item>Tempo traveller 26, 17, 13 seater</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/">
                                    <NavDropdown.Item>Bus 35, 55 seater</NavDropdown.Item>
                                </LinkContainer>

                               

                            </NavDropdown>

                            <NavDropdown title="OUR TARIFF" id="basic-nav-dropdown">
                                <LinkContainer to="/Plans" onClick={handleNavItemClick}>
                                    <NavDropdown.Item>See Tariff</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>

                            <LinkContainer to="/Photoes" onClick={handleNavItemClick}>
                                <Nav.Link>GALLERY</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/contact" onClick={handleNavItemClick}>
                                <Nav.Link>CONTACT</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default BasicExample;
