import React, { useState } from 'react';

function BusBookingForm() {
    const [vehicleType, setVehicleType] = useState('bus');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [date, setDate] = useState('');
    const [adultsCount, setAdultsCount] = useState(1);
    const [childrenCount, setChildrenCount] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        // You can add your booking logic here
        console.log('Booking submitted:', { vehicleType, from, to, date, adultsCount, childrenCount });
    };

    return (

        <div className='bookingform'>


                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14239.061937466986!2d80.8869475!3d26.8474104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bff6ea4883ecd%3A0x34103e437638fd72!2sEmpire%20travel%20services!5e0!3m2!1sen!2sin!4v1711955628309!5m2!1sen!2sin"  style={{width:'100%',height:'100%'}}   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
           
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3559.714062226454!2d80.88412757543723!3d26.849045476685095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUwJzU2LjYiTiA4MMKwNTMnMTIuMSJF!5e0!3m2!1sen!2sin!4v1712992407088!5m2!1sen!2sin" style={{width:'100%',height:'100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


        </div>
    );
}

export default BusBookingForm;
