import React from 'react'

import about from './Img/about.gif'
import Call from './Img/phone.png'
import whats from './Img/whatsapp.png'

const Contain = () => {
    return (


        <>

            <br />

            <center><h1 style={{ fontFamily: 'Times New Roman Times serif', fontWeight: 'bold', }} className='heading'>  WELCOME TO EMPIRE TRAVEL SERVICES</h1></center>

            <div className='contain'>

                <div className='cont2'>
                    <h1>EMPIRE TRAVEL SERVICES</h1>
                    <p>EMPIRE TRAVEL SERVICES IS A <b>Trusted Taxi Service In Lucknow</b>,Providing Reliable And Affordable Transportation Since 15 Years.
                    </p>

                    <p> Whether You Need A Ride To The Airport, A Quick Trip Around Town , Or Transportation For A Speacial Event
                        We Offers Speacial Services To Ayodhya Ji Darshan Same Day , Same Day Kashi Vishwanath Tour.
                    </p>
                    <p>India's Largest Cloud Based Digital Discovery And Services Provisioning Platform For The Taxi Rental Industry
                        Serving Over 6000 Customers In 100+ Cities.
                    </p>
                    <p> We Are Into Govt.Deparment Serving <b>Our Services More Than 10 Years</b></p>


                    <a href='https://wa.me/9621909090'><button>Whatsapp</button></a>
                    <a href='Tel:9621909090'><button>Call Now</button></a>
                </div>

                <div className='cont2'>
                    <img src={about} />
                </div>

            </div>


            <div className="icon-bar">
                <a href="Tel:9621909090" className="facebook"> <img src={Call}/><br/> <span>CALL US </span>  </a>
            </div>

            
            <div className="icon-bar2">
                <a href="https://wa.me/9621909090" className="facebook"> <img src={whats}/><br/> <span>WHATSAPP </span>  </a>
            </div>

        </>
    )
}

export default Contain