import React from 'react'

import trav1 from './Img/trav1.jpg'
import trav2 from './Img/trav2.jpg'
import trav3 from './Img/trav3.jpg'
import trav4 from './Img/trav4.jpg'
import trav5 from './Img/trav5.jpg'
import trav6 from './Img/trav6.jpg'
import trav7 from './Img/trav7.jpg'
import trav8 from './Img/trav8.jpg'
import trav9 from './Img/trav9.jpg'
import trav10 from './Img/trav10.jpg'
import trav11 from './Img/trav11.jpg'
import trav12 from './Img/trav12.jpg'


import trav13 from './Img/ayodhya.jpg'
import trav14 from './Img/roomi.jpg'
import trav15 from './Img/imam.jpg'

const Travels = () => {
    return (
        <>


        <center><h1>Our Travels City</h1></center>



        <div className='travel'>
                <div className='trav2'>
                    <img src={trav13} />
                </div>
                <div className='trav2'>
                    <img src={trav14} />
                </div>
                <div className='trav2'>
                    <img src={trav15} />
                </div>
            </div>




            <div className='travel'>
                <div className='trav2'>
                    <img src={trav1} />
                </div>
                <div className='trav2'>
                    <img src={trav2} />
                </div>
                <div className='trav2'>
                    <img src={trav3} />
                </div>
            </div>


{/* 
            <div className='travel'>
                <div className='trav2'>
                    <img src={trav4} />
                </div>
                <div className='trav2'>
                    <img src={trav5} />
                </div>
                <div className='trav2'>
                    <img src={trav6} />
                </div>
            </div> */}

{/* 
            <div className='travel'>
                <div className='trav2'>
                    <img src={trav7} />
                </div>
                <div className='trav2'>
                    <img src={trav8} />
                </div>
                <div className='trav2'>
                    <img src={trav9} />
                </div>
            </div> */}


            {/* <div className='travel'>
                <div className='trav2'>
                    <img src={trav10} />
                </div>
                <div className='trav2'>
                    <img src={trav11} />
                </div>
                <div className='trav2'>
                    <img src={trav12} />
                </div>
            </div> */}
        </>
    )
}

export default Travels