import React from 'react'
import merc from './Img/mercidiseA4.jpg';
import audi from './Img/AudiA4.jpg';
import bmw from './Img/BMWA4.jpg';
import jaguar from './Img/jaguarA4.jpg';

const Hire = () => {
    return (
        <>
            <div className='hire'>
                <h1>Hire Luxury Cars in Lucknow</h1>
                <p>We are providing all types of luxury cars for wedding, events
                    for holiday trip , birthday party pre wedding shooting
                    Corporate meetings .</p>
            </div>

            <div className='cars'>

                <div className='cars2'>

                    <h1>Mercedes e class </h1>
                    <img src={merc} />
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>

                <div className='cars2'>
                    <h1>Audi A6</h1>
                    <img src={audi} />
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>

                <div className='cars2'>
                    <img src={bmw} />
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>

                <div className='cars2'>
                    <img src={jaguar} />
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>

            </div>



        </>
    )
}

export default Hire