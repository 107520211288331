import React, { useState } from 'react';
import Modal from 'react-modal';
import bus1 from './Img/ciazes.png';
import bus2 from './Img/honda.png';
import bus3 from './Img/ertiga.jpg';
import vitara from './Img/fortu.png';
import bus7 from './Img/bmw2.jpg';
import bus8 from './Img/fx2.jpg';

const Buses = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState("");

    const openModal = (imageSrc, name) => {
        setSelectedImage(imageSrc);
        setSelectedName(name);
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setSelectedImage(null);
        setSelectedName("");
        setModalIsOpen(false);
    }

    
    const modalStyle = {
        content: {
            width: '400px',
            height: '500px',
            backgroundColor: 'grey',
            margin: 'auto',
        }
    };
    
    if (window.innerWidth <= 768) {
        modalStyle.content.width = '100%';
    }
    
    // Add the media query directly in the style object
    if (window.innerWidth <= 768) {
        modalStyle.content = {
            width: '300px',
            height: '500px',
            backgroundColor: 'grey',
            margin: '0',
        };
    }

    return (
        <>
            <div className='buses'>
                <div className='bus2' onClick={() => openModal(bus1, "CIAZ")}>
                    <img src={bus1} alt="bus1" />
                    <p>CIAZ</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
                <div className='bus2' onClick={() => openModal(bus2, "HONDA AMAZE")}>
                    <img src={bus2} alt="bus2" />
                    <p>HONDA AMAZE</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
                <div className='bus2' onClick={() => openModal(bus3, "ERTIGA")}>
                    <img src={bus3} alt="bus3" />
                    <p>ERTIGA</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
            </div>


            <div className='buses'>
                <div className='bus2' onClick={() => openModal(vitara, "FORTUNAR")}>
                    <img src={vitara} alt="vitara" />
                    <p>FORTUNAR</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
                <div className='bus2' onClick={() => openModal(bus8, "MERCIDISE")}>
                    <img src={bus8} alt="bus8" />
                    <p>MERCIDISE</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
                <div className='bus2' onClick={() => openModal(bus7, "BMW")}>
                    <img src={bus7} alt="bus7" />
                    <p>BMW</p>
                    <a href='Tel:+91 9621909090'><button>Book Now </button></a>
                </div>
            </div>


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Selected Image"
                style={modalStyle}
            >
                <div className="modal-content">
                    <p>{selectedName}</p>
                    <img src={selectedImage} alt="Selected" />
                    <button onClick={closeModal}>Close</button>
                </div>
            </Modal>
        </>
    );
}

export default Buses;
