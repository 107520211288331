import React from 'react'

export const Head = () => {
    return (
        <div className='head'>
            <div className='head2' >
                <a href='Tel:9621909090'><i class="fa fa-phone"></i>+91  9621909090</a>
                <p>ISO 90001:2015</p>

            </div>


            <div className='head2'>
                <p>empiretravel.lucknow@gmail.com</p>
            </div>


            <div className='head2'>
                <div className='head3'>
                    <a href=''><i class="fa fa-facebook"></i> </a>
                    <a href=''><i class="fa fa-instagram"></i> </a>
                    <a href=''><i class="fa fa-youtube-play"></i> </a>
                </div>
            </div>
        </div>
    )
}

