import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
    return (


        <div>


            <div className='footer'>

                <div className='foot'>
                    <h1>COMPANY</h1>
                    <p style={{color:'white',marginLeft:'10px',marginTop:"20px"}}>EMPIRE TRAVEL SERVICES IS A Trusted Taxi Service In Lucknow,Providing Reliable And Affordable Transportation Since 15 Years.</p>
                </div>

                <div className='foot'>
                    <h1>COMPANY</h1>
                    <br />
                    <Link to='/'> <p><a href=''>Home</a></p></Link>
                    <Link to='/Plans'> <p><a href=''>Plans </a></p></Link>
                    <Link to='/contact'> <p><a href=''>Contact </a></p></Link>

                </div>

                <div className='foot'>
                    <h1> OUR TRIP </h1>
                    <br />
                    <p> <a href=''> Birthday party</a></p>
                    <p><a href=''> Pre wedding shoot </a></p>
                    <p> <a href=''> Event</a></p>
                    <p> <a href=''>Pickup and drop </a></p>
                    <p> <a href=''>  Marriage</a></p>
                    <p> <a href=''> Ayodhya darshan round trip</a></p>
                </div>




                <div className='foot'>
                    <h1>GET TOUCH</h1>
                    <br />
                    <p> <a href=''><i class="fa fa-envelope"></i> empiretravel.lucknow@gmail.com</a></p>
                    <p><a href='Tel:9621909090'><i class="fa fa-phone"></i> +91 9621909090</a></p>
                    <p><a href='Tel:9235149881'><i class="fa fa-phone"></i> +91  9235149881</a></p>
                    <h1>VISIT US </h1>
                    <p><a href=''><i class="fa fa-map-marker"></i> Empire Plaza Kothari Bandu Road   Madhuvan Lawn  Opposite Swami Yoganand Inter College Rajajipuram Lucknow  - 226017 </a></p>

                </div>

            </div>



        </div>
    )
}

export default Footer