import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const Form = () => {
    const [formType, setFormType] = useState('local');
    const form = useRef();

    const handleFormChange = (type) => {
        setFormType(type);
    };

    const sendEmailAndWhatsApp = (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);

        // Send email
        emailjs.sendForm('service_vz4nhls', 'template_iihqd8h', form.current, 'gmWNCxjnXAk0DzjJY')
            .then((result) => {
                console.log(result.text);
            })
            .catch((error) => {
                console.log(error.text);
            });

        // Send WhatsApp message
        let message = "Form Data:\n";
        for (let [key, value] of formData.entries()) {
            message += `${key}: ${value}\n`;
        }

        const whatsappNumber = "9235149881"; // Replace with your WhatsApp number
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');

        // Reset form fields
        form.current.reset();
    };

    return (
        <div className='form-container' style={{backgroundColor:'lightgoldenrodyellow'}}>
            <button onClick={() => handleFormChange('local')}>Local Taxi</button>
            <button onClick={() => handleFormChange('outstanding')}>Outstation</button>
            <button onClick={() => handleFormChange('airport')}>Airport</button>

            {formType === 'local' && <LocalForm form={form} sendEmailAndWhatsApp={sendEmailAndWhatsApp} />}
            {formType === 'outstanding' && <OutstandingForm form={form} sendEmailAndWhatsApp={sendEmailAndWhatsApp} />}
            {formType === 'airport' && <AirportForm form={form} sendEmailAndWhatsApp={sendEmailAndWhatsApp} />}
        </div>
    );
};

const LocalForm = ({ form, sendEmailAndWhatsApp }) => {
    return (
        <div className='form-container'>
            <h2>Local Taxi Form</h2>
            <form ref={form} onSubmit={sendEmailAndWhatsApp} style={{backgroundColor:'lightgoldenrodyellow'}} >
                <label>
                    Select Vehicle :  - 
                    <select name="Vehicle" required>
                        <option value="Audi A6">Audi A6</option>
                        <option value="Jaguar">Mercedes e class </option>
                        <option value="Mercedes">Jaguar Xf </option>
                        <option value="Innova crysta">Dzire</option>
                        <option value="Innova">BMW</option>
                        <option value="Eartiga">Fortuner </option>
                        <option value="Dezire">Ertiga </option>
                        <option value="Zest">Honda City</option>
                        <option value="Honda Amaze">Honda Amaze </option>
                        <option value="Honda City">Ciaz</option>
                        <option value="Ciaz">Tempo traveller 26</option>
                        <option value="Bolero">Tempo traveller 17</option>
                        <option value="Tempo traveller 13 seater">Tempo traveller 13 seater</option>
                        <option value="Bus 35 seater">Bus 35 seater</option>
                        <option value="Bus 55 seater">Bus 55 seater</option>
                    </select>
                </label>
                <label>
                    Pick Up City:
                    <input type="text" name="pickupCity" required />
                </label>
                <label>
                    Drop City:
                    <input type="text" name="dropCity" required />
                </label>
                <label>
                    Date & Time:
                    <input type="datetime-local" name="dateTime" required />
                </label>
                <label>
                    Contact No.:
                    <input type="tel" name="contactNo" required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" required />
                </label>
                <label>
                    Select Trip :  - 
                    <select name="Trip" required>
                        <option value="Birthday Party">Birthday Party</option>
                        <option value="Pre Wedding shoot">Pre Wedding shoot</option>
                        <option value="Event">Event</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Ayodhya Darshan Round Trip">Ayodhya Darshan Round Trip</option>
                       
                    </select>
                </label>
                <button type="submit">Send Form</button>

            </form>
            <a href='https://wa.me/9621909090'><button>Whatsapp</button></a>
            <a href='Tel:9621909090'><button>Call Now</button></a>
        </div>
    );
};

const OutstandingForm = ({ form, sendEmailAndWhatsApp }) => {
    return (
        <div className='form-container'>
            <h2>Out station Form</h2>
            <form ref={form} onSubmit={sendEmailAndWhatsApp} style={{backgroundColor:'lightgoldenrodyellow'}}>
                <label>
                    Select Vehicle:
                    <select name="Vehicle" required>
                    <option value="Audi A6">Audi A6</option>
                        <option value="Jaguar">Mercedes e class </option>
                        <option value="Mercedes">Jaguar Xf </option>
                        <option value="Innova crysta">Dzire</option>
                        <option value="Innova">BMW</option>
                        <option value="Eartiga">Fortuner </option>
                        <option value="Dezire">Ertiga </option>
                        <option value="Zest">Honda City</option>
                        <option value="Honda Amaze">Honda Amaze </option>
                        <option value="Honda City">Ciaz</option>
                        <option value="Ciaz">Tempo traveller 26</option>
                        <option value="Bolero">Tempo traveller 17</option>
                        <option value="Tempo traveller 13 seater">Tempo traveller 13 seater</option>
                        <option value="Bus 35 seater">Bus 35 seater</option>
                        <option value="Bus 55 seater">Bus 55 seater</option>
                    </select>
                </label>
                <label>
                    Pick Up City:
                    <input type="text" name="pickupCity" required />
                </label>
                <label>
                    Drop City:
                    <input type="text" name="dropCity" required />
                </label>
                <label>
                    Date & Time:
                    <input type="datetime-local" name="dateTime" required />
                </label>
                <label>
                    Contact No.:
                    <input type="tel" name="contactNo" required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" required />
                </label>
                <label>
                Select Trip :  - 
                    <select name="Trip" required>
                        <option value="Birthday Party">Birthday Party</option>
                        <option value="Pre Wedding shoot">Pre Wedding shoot</option>
                        <option value="Event">Event</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Ayodhya Darshan Round Trip">Ayodhya Darshan Round Trip</option>
                      
                    </select>
                </label>
                <button type="submit">Send Form</button>

            </form>
            <a href='https://wa.me/9621909090'><button>Whatsapp</button></a>
            <a href='Tel:9621909090'><button>Call Now</button></a>
        </div>
    );
};

const AirportForm = ({ form, sendEmailAndWhatsApp }) => {
    return (
        <div className='form-container'>
            <h2>Airport Form</h2>
            <form ref={form} onSubmit={sendEmailAndWhatsApp} style={{backgroundColor:'lightgoldenrodyellow'}}>
                <label>
                    Select Vehicle:
                    <select name="Vehicle" required>
                    <option value="Audi A6">Audi A6</option>
                        <option value="Jaguar">Mercedes e class </option>
                        <option value="Mercedes">Jaguar Xf </option>
                        <option value="Innova crysta">Dzire</option>
                        <option value="Innova">BMW</option>
                        <option value="Eartiga">Fortuner </option>
                        <option value="Dezire">Ertiga </option>
                        <option value="Zest">Honda City</option>
                        <option value="Honda Amaze">Honda Amaze </option>
                        <option value="Honda City">Ciaz</option>
                        <option value="Ciaz">Tempo traveller 26</option>
                        <option value="Bolero">Tempo traveller 17</option>
                        <option value="Tempo traveller 13 seater">Tempo traveller 13 seater</option>
                        <option value="Bus 35 seater">Bus 35 seater</option>
                        <option value="Bus 55 seater">Bus 55 seater</option>
                    </select>
                </label>
                <label>
                    Pick Up City:
                    <input type="text" name="pickupCity" required />
                </label>
                <label>
                    Drop City:
                    <input type="text" name="dropCity" required />
                </label>
                <label>
                    Date & Time:
                    <input type="datetime-local" name="dateTime" required />
                </label>
                <label>
                    Contact No.:
                    <input type="tel" name="contactNo" required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" required />
                </label>
                <label>
                Select Trip :  - 
                    <select name="Trip" required>
                        <option value="Birthday Party">Birthday Party</option>
                        <option value="Pre Wedding shoot">Pre Wedding shoot</option>
                        <option value="Event">Event</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Ayodhya Darshan Round Trip">Ayodhya Darshan Round Trip</option>
                       
                    </select>
                </label>
                <button type="submit">Send Form</button>

            </form>
            <a href='https://wa.me/9621909090'><button>Whatsapp</button></a>
            <a href='Tel:9621909090'><button>Call Now</button></a>

        </div>
    );
};

export default Form;
