import React from 'react'
import Footer from './Footer'
import Contain from './Contain'

const About = () => {
  return (
    <>
    <Contain/>
    
    <Footer/>
    
    </>
  )
}

export default About