import React from 'react'
import Footer from './Footer'

import gal1 from './Img/gal1.jpg'
import gal2 from './Img/gal2.jpg'
import gal3 from './Img/gal3.jpg'
import gal4 from './Img/gal4.jpg'
import gal5 from './Img/gal6.jpg'
import gal6 from './Img/gal7.jpg'
import gal7 from './Img/gal8.jpg'
import gal8 from './Img/gal9.jpg'

import newgal1 from './Img/newgal1.jpg';
import newgal2 from './Img/newgal2.jpg';
import newgal3 from './Img/newgal3.jpg';
import newgal4 from './Img/newgal4.jpg';

import gal10 from './Img/gal10.jpg'
import gal11 from './Img/gal11.jpg'
import gal12 from './Img/gal12.jpg'
import gal13 from './Img/gal13.jpg'
import tampo1 from './Img/tampo.jpg'
import tampo12 from './Img/tampo2.jpg'



const Photoes = () => {
    return (
        <>

            <center><h2>Our Gallery</h2></center>

            <div className='images'>

                <div className='imag2'>
                    <img src={gal1} />
                </div>
                <div className='imag2'>
                    <img src={gal2} />
                </div>
                <div className='imag2'>
                    <img src={gal3} />
                </div>
                <div className='imag2'>
                    <img src={gal4} />
                </div>

            </div>


            <div className='images'>

                <div className='imag2'>
                    <img src={gal5} />
                </div>
                <div className='imag2'>
                    <img src={gal6} />
                </div>
                <div className='imag2'>
                    <img src={gal7} />
                </div>
                <div className='imag2'>
                    <img src={gal8} />
                </div>

            </div>




            <div className='images'>

                <div className='imag2'>
                    <img src={gal10} />
                </div>
                <div className='imag2'>
                    <img src={gal11} />
                </div>
                <div className='imag2'>
                    <img src={gal12} />
                </div>
                <div className='imag2'>
                    <img src={gal13} />
                </div>

            </div>







            <div className='images'>

                <div className='imag2'>
                    <img src={newgal1} />
                </div>
                <div className='imag2'>
                    <img src={newgal2} />
                </div>
                <div className='imag2'>
                    <img src={newgal3} />
                </div>
                <div className='imag2'>
                    <img src={newgal4} />
                </div>

            </div>


            <div className='tampo'>

                <div className='tampo2' >
                    <img src={tampo1} />
                </div>
                <div className='tampo2'>
                    <img src={tampo12} />
                </div>


            </div>


            <Footer />
        </>
    )
}

export default Photoes
