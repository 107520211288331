import React from 'react'
import Footer from './Footer'
import BusBookingForm from './Booking'
import Form from '../component/Form'

const Contact = () => {
  return (
   <>
   <center><h1>Contact Us </h1></center>
   <Form/>
   <BusBookingForm/>
   
   <Footer/>

   </>
  )
}

export default Contact