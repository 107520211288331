
import React from 'react'

import Carousal from './Carousal';

import Footer from './Footer';
import Contain from './Contain';
import Buses from './Buses';
import Booking from './Booking';
import Travels from './Travels';
import Form from './Form';
import Hire from './Hire';
import Vanslide from './Vanslide';




const Home = () => {
  return (

    <>
      <div className='mains'>
        <Carousal />
        <Form />
        <Contain />
        <Buses /><hr/>
        <Vanslide/>
        <Travels />
       
        <Hire/>
        <Booking />
       
        <Footer />
      

      </div>


    </>





  )
}

export default Home