import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import bus1 from './Img/audia6.jpg';
import bus2 from './Img/swift.jpg';
import bus3 from './Img/jaguarxf.jpg';
import bus4 from './Img/city.jpg';
import bus5 from './Img/crysta.jpg';
import bus6 from './Img/mercidise e .jpg';
import bus8 from './Img/bmw.jpg';

const Vanslide = () => {


    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };


    return (
        <div className='slides' >
            <Slider {...settings}>
                <div className='vans' >
                    <img src={bus1} alt="Bus 1" />
                    <h1 >Audi A6</h1>
                </div>
                <div className='vans' >
                    <img src={bus2} alt="Bus 2" />
                    <h1>Swift Dzire</h1>
                </div>
                <div className='vans' >
                    <img src={bus3} alt="Bus 3" />
                    <h1>Jaguar XF</h1>
                </div>
                <div className='vans' >
                    <img src={bus4} alt="Vitara" />
                    <h1>Honda City</h1>
                </div>
                <div className='vans' >
                    <img src={bus5} alt="Bus 7" />
                    <h1>Crysta</h1>
                </div>
                <div className='vans' >
                    <img src={bus6} alt="Fortuner" />
                    <h1>Mercedes E Class</h1>
                </div>
                <div className='vans' >
                    <img src={bus8} alt="Fortuner" />
                    <h1>BMW</h1>
                </div>
            </Slider>



        </div>
    );
};

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} style={{ display: "block", left: '-20px', zIndex: '1', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} onClick={onClick}>
            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
        </div>
    );
};

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} style={{ display: "block", right: '-20px', zIndex: '1', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} onClick={onClick}>
            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
        </div>
    );
};

export default Vanslide;
