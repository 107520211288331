import React from 'react'
import Footer from './Footer';

const Plans = () => {
    return (
        <>

            <div className='plans'>

                <hr />
                <h1><u>Our Tariff</u></h1>

                <h2><i class="fa fa-dot-circle-o"></i> 3 Days 2 Night</h2>

                <table>
                    <tr>
                        <th>Option</th>
                        <th>Vehicle</th>
                        <th>Route</th>
                        <th>Package</th>
                    </tr>
                    <tr>
                        <td className="option">1</td>
                        <td className="vehicle">Innova Crysta</td>
                        <td>Lucknow to Ayodhya ➔ Varanasi ➔ Allahabad ➔ back to Lucknow</td>
                        <td className="package">₹22,500</td>
                    </tr>
                    <tr>
                        <td className="option">2</td>
                        <td className="vehicle">Swift Dzire / Honda Amaze</td>
                        <td>Lucknow to Ayodhya ➔ Varanasi ➔ Allahabad ➔ back to Lucknow</td>
                        <td className="package">₹16,000</td>
                    </tr>
                    <tr>
                        <td className="option">3</td>
                        <td className="vehicle">Maruti Suzuki Ertiga</td>
                        <td>Lucknow to Ayodhya ➔ Varanasi ➔ Allahabad ➔ back to Lucknow</td>
                        <td className="package">₹19,000</td>
                    </tr>
                </table>


                <h2><i class="fa fa-dot-circle-o"></i> 2 Days 1 Night</h2>

                <table>
                    <tr>
                        <th  >Option</th>
                        <th >Vehicle</th>
                        <th>Route</th>
                        <th>Distance</th>
                        <th>Amount</th>
                    </tr>
                    <tr>
                        <td className="option">1</td>
                        <td  className="vehicle">Innova Crysta</td>
                        <td>Lucknow to Ayodhya ➔ Nimsar ➔ Lucknow (local)</td>
                        <td className="package">800km</td>
                        <td className="package">₹18,000</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td className="vehicle">Swift Dzire / Honda Amaze</td>

                        <td>Lucknow to Ayodhya ➔ Nimsar ➔ Lucknow (local)</td>
                        <td></td>
                        <td  className='package'>₹13,000</td>
                    </tr>
                    <tr>
                        <td className="option">3</td>
                        <td className="vehicle">Maruti Suzuki Ertiga</td>
                        <td>Lucknow to Ayodhya ➔ Nimsar ➔ Lucknow (local)</td>
                        <td></td>
                        <td className="package">₹16,000</td>
                    </tr>
                </table>


                <h2><i class="fa fa-dot-circle-o"></i> 2 Days 1 Night</h2>

                <table>
                    <tr>
                        <th  >Option</th>
                        <th>Vehicle</th>
                        <th>Route</th>
                        <th>Distance</th>
                        <th>Amount</th>
                    </tr>
                    <tr>
                        <td className="option">1</td>
                        <td className="vehicle">Innova Crysta</td>
                        <td>Lucknow to Allahabad ➔ Chitrakoot ➔ Varanasi ➔ back to Lucknow</td>
                        <td className="package">1000km</td>
                        <td className="package">₹23,000</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td className="vehicle">Swift Dzire / Honda Amaze</td>
                        <td>Lucknow to Allahabad ➔ Chitrakoot ➔ Varanasi ➔ back to Lucknow</td>
                        <td></td>
                        <td className="package">₹16,000</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td className="vehicle">Maruti Suzuki Ertiga</td>
                        <td>Lucknow to Allahabad ➔ Chitrakoot ➔ Varanasi ➔ back to Lucknow</td>
                        <td></td>
                        <td className="package">₹19,000</td>
                    </tr>
                </table>

                <br/><br/>
                <table>
                    <tr>
                        <th>Vehicle</th>
                        <th>Extra Km Charge</th>
                    </tr>
                    <tr>
                        <td className="vehicle">Innova Crysta</td>
                        <td>₹18 per km</td>
                    </tr>
                    <tr>
                        <td className="vehicle">Swift Dzire</td>
                        <td>₹12 per km</td>
                    </tr>
                    <tr>
                        <td className="vehicle">Maruti Suzuki Ertiga</td>
                        <td>₹15 per km</td>
                    </tr>
                </table>




                <br />

            </div>
            <Footer />
        </>
    )
}

export default Plans



